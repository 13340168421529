/* Copyright 2023 Amazon.com and its affiliates; all rights reserved. This file is Amazon Web Services Content and may not be duplicated or distributed without permission. */

import React, { useEffect, useState } from 'react';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';
import { UserContext } from '../context/userContext';
import { UserInfoContextType } from '../@types/user';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Table, Box, SpaceBetween, Button, TextFilter, Header, Pagination, CollectionPreferences } from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';
import { columnDefinitions, getMatchesCountText, paginationLabels, collectionPreferencesProps, Item, Preferences } from './MyItemsTableConfig';
import { ItemContext } from '../context/itemContext';
import { SelectedItemContextType } from '../@types/item';
import ItemDetail from './ItemDetail';
import BusinessFit from './BusinessFit';
import SolutionsFit from './SolutionsFit';
import EditItemDetails from './EditItemDetail';


export default function MyItems() {


    const LOCALE = 'en';
    const apiUrl = 'https://h85kpb567c.execute-api.eu-west-3.amazonaws.com/prod';
    const path = '/getMyItems';

    //Context Information 
    const { user } = React.useContext(UserContext) as UserInfoContextType;
    const { item, updateItem } = React.useContext(ItemContext) as SelectedItemContextType;

    //Table Information 
    //TODO To remove sample data and enable lazy loading feature
    const [tableItems, setTableItems] = useState<Item[]>([]);
    const [tableLoadingActive, setTableLoadingActive] = useState(false);

    //Button show state depending on selected item
    const [showViewButton, setShowViewButton] = useState(false);
    const [showAddBusinessFitButton, setShowAddBusinessFitButton] = useState(false);
    const [showAddSolutionsFitButton, setShowSolutionsFitButton] = useState(false);
    const [showAddWinLossButton, setShowWinLossButton] = useState(false);
    const [showEditBusinessFitButton, setShowEditBusinessFitButton] = useState(false);


    useEffect(() => {
        setTableLoadingActive(true);
        const getTableItems = async () => {
            const token = (await fetchAuthSession()).tokens?.idToken?.toString();
            const body = {
                user: user.username
            };
            const response = await fetch(`${apiUrl}${path}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(body)

            });
            const data = await response.json();
            console.log(`Retrieved Data: ${JSON.stringify(data.dataset)}`);
            setTableItems(data.dataset);

            return data;
        }
        if (user.username !== 'post 1') {
            getTableItems().then(() => { setTableLoadingActive(false) });
        }

    }, [user])



    interface EmptyStateProps {
        title: string;
        subtitle: string;
        action: React.ReactNode;
    }


    const EmptyState = ({ title, subtitle, action }: EmptyStateProps) => {
        return (
            <Box textAlign="center" color="inherit">
                <Box variant="strong" textAlign="center" color="inherit">
                    {title}
                </Box>
                <Box variant="p" padding={{ bottom: 's' }} color="inherit">
                    {subtitle}
                </Box>
                {action}
            </Box>
        );
    }

    const [preferences, setPreferences] = useState<Preferences>({
        pageSize: 10,
        contentDisplay: [
            { id: 'companyName', visible: true },
            { id: 'owner', visible: true },
            { id: 'requestType', visible: true },
            { id: 'tenderOutcome', visible: true},
            { id: 'qualificationState', visible: true },
            { id: 'postQualificationBusiness', visible: true },
            { id: 'postQualificationSolution', visible: true },
            { id: 'createdAt', visible: true },
            { id: 'updatedAt', visible: false },
            { id: 'companyIndustry', visible: false },
            { id: 'opportunityType', visible: false },
            { id: 'oppyForecastedDate', visible: false },
            { id: 'connectSpecialist', visible: false },
            { id: 'id', visible: false },
        ],
    });
    const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
        tableItems,
        {
            filtering: {
                empty: <EmptyState title="No Items" subtitle='' action={<Button href='/NewRFX'>Create RFX</Button>} />,
                noMatch: (
                    <EmptyState
                        title="No matches"
                        subtitle=''
                        action={<Button onClick={() => actions.setFiltering('')}>Clear filter</Button>}
                    />
                ),
            },
            pagination: { pageSize: preferences.pageSize },
            sorting: {},
            selection: {}
        }
    );
    const { selectedItems } = collectionProps;

    useEffect(() => {
        console.log(`Selected Items: ${JSON.stringify(selectedItems)}`)
        if (selectedItems!.length >= 1) {
            console.log('selected Items not empty')
            setShowViewButton(true);
            if (selectedItems![0].postQualificationBusiness === 'Incomplete') {
                console.log('show add business fit button')
                setShowAddBusinessFitButton(true);
                setShowEditBusinessFitButton(true);
            }
            if (selectedItems![0].postQualificationSolution === 'Incomplete' && selectedItems![0].postQualificationBusiness === 'Completed') {
                setShowSolutionsFitButton(true);
                setShowEditBusinessFitButton(true);
            }
            if (selectedItems![0].winLossAnalysis === 'Incomplete') {
                setShowWinLossButton(true);
                setShowEditBusinessFitButton(true);
            }
            if (selectedItems![0].preQualiChecklist === 'Completed') {
                setShowEditBusinessFitButton(true);
            }
            if (selectedItems![0].postQualificationBusiness === 'Completed') {
                console.log('show add business fit button')
                setShowEditBusinessFitButton(true);
                setShowAddBusinessFitButton(false);
            }
            if (selectedItems![0].postQualificationSolution === 'Completed') {
                setShowEditBusinessFitButton(true)
                setShowSolutionsFitButton(false);
            }
            if (selectedItems![0].winLossAnalysis === 'Completed') {
                setShowEditBusinessFitButton(true);
                setShowAddBusinessFitButton(false);
            }
        } else {
            setShowViewButton(false);
        }

    }, [selectedItems])


    const content = (
        <>
            <I18nProvider locale={LOCALE} messages={[messages]}>

                <Table
                    {...collectionProps}
                    selectionType="single"
                    loading={tableLoadingActive}
                    loadingText='Loading Table Items'
                    header={
                        <Header
                            counter={selectedItems!.length ? `(${selectedItems!.length}/${tableItems!.length})` : `(${tableItems!.length})`}
                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    {showViewButton === true && (
                                        <Button
                                            aria-label="view"
                                            onClick={() => {
                                                updateItem({ id: selectedItems![0].id, owner: selectedItems![0].owner, visible: true, soutionsFitVisible: false, businessFitVisible: false, editBusinessFitVisible: false });
                                            }}
                                        >View</Button>
                                    )}
                                    {showAddBusinessFitButton === true && (
                                        <Button
                                            aria-label="add"
                                            onClick={() => {
                                                updateItem({ id: selectedItems![0].id, owner: selectedItems![0].owner, visible: false, soutionsFitVisible: false, businessFitVisible: true, editBusinessFitVisible: false });
                                            }}
                                        >Add Business Fit</Button>
                                    )}
                                    {showAddSolutionsFitButton === true && (
                                        <Button
                                            aria-label="add"
                                            onClick={() => {
                                                updateItem({ id: selectedItems![0].id, owner: selectedItems![0].owner, visible: false, soutionsFitVisible: true, businessFitVisible: false, editBusinessFitVisible: false });
                                            }}
                                        >Add Solutions Fit</Button>
                                    )}
                                    {showAddWinLossButton === true && (
                                        <Button
                                            disabled
                                            aria-label="add"
                                            onClick={() => {
                                                setShowWinLossButton(false);
                                            }}
                                        >Add Win/Loss</Button>
                                    )}

                                    {showEditBusinessFitButton === true && (
                                        <Button
                                            aria-label="edit"
                                            onClick={() => {
                                                updateItem({ id: selectedItems![0].id, owner: selectedItems![0].owner, visible: false, soutionsFitVisible: false, businessFitVisible: false, editBusinessFitVisible: true });

                                            }}
                                        >Edit</Button>
                                    )}
                                    <Button
                                        iconName="refresh"
                                        aria-label="refresh"
                                        variant="icon"
                                        onClick={() => {
                                            window.location.reload();
                                        }}
                                    />
                                </SpaceBetween>
                            }
                        >
                            RFx Items
                        </Header>
                    }
                    columnDefinitions={columnDefinitions}
                    columnDisplay={preferences.contentDisplay}
                    items={items}
                    pagination={<Pagination {...paginationProps} ariaLabels={paginationLabels} />}
                    filter={
                        <TextFilter
                            {...filterProps}
                            countText={getMatchesCountText(filteredItemsCount!)}
                            filteringAriaLabel="Filter Items"
                        />
                    }
                    preferences={
                        <CollectionPreferences
                            {...collectionPreferencesProps}
                            preferences={preferences}
                            onConfirm={({ detail }) => {
                                const preferences = detail as Preferences
                                setPreferences(preferences)
                            }}
                        />
                    }
                />


                {item.visible && <ItemDetail data={tableItems} />}
                {item.businessFitVisible && <BusinessFit data={tableItems} />}
                {item.soutionsFitVisible && <SolutionsFit data={tableItems} />}
                {item.editBusinessFitVisible && <EditItemDetails data={tableItems} />}

                <div>

                </div>
            </I18nProvider>
        </>
    )

    return (
        content
    )

}