import { Container, Header, ExpandableSection, Box, SpaceBetween, ColumnLayout, StatusIndicator, SplitPanel, Slider, FormField } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { formField } from './common/createField';

export default function ItemDetailBusinesshecklist(props: any) {

  const data = props.data;
  const sourceData = props.sourceData;
  
  //Business Fit Items

  //Budget
  const [pricingApproach, setPricingAproach] = useState('No');
  const [increaseSize, setIncreaseSize] = useState('No');
  const [owp, setOwp] = useState('No');
  const [currentSpent, setCurrentSpent] = useState('No');

  //Authority
  const [buyerPersoa, setBuyerPersona] = useState('No');
  const [procurementAccess, setProcurementAccess] = useState('No');
  const [decisionProcess, setDecisionProcess] = useState('No');


  //Need
  const [objective, setObjective] = useState('Tactical');
  const [specificOutcome, setSpecificOutcome] = useState('No');
  const [obtainAWSCapability, setObtainAWSCapability] = useState('No');
  const [partnerCost, setPartnerCost] = useState('No');

  //Timing
  const [timescales, setTimescales] = useState('No');
  const [compellingEvent, setCompellingEvent] = useState('No');
  const [capabilityDeadline, setCapabilityDeadline] = useState('No');


  useEffect(() => {
    if (sourceData[0]) {
      setPricingAproach(sourceData[0].pricingApproach);
      setIncreaseSize(sourceData[0].increaseSize);
      setOwp(sourceData[0].owp);
      setCurrentSpent(sourceData[0].currentSpent);
      setBuyerPersona(sourceData[0].buyerPersoa);
      setProcurementAccess(sourceData[0].procurementAccess);
      setDecisionProcess(sourceData[0].decisionProcess);
      setObjective(sourceData[0].objective);
      setSpecificOutcome(sourceData[0].specificOutcome);
      setObtainAWSCapability(sourceData[0].obtainAWSCapability);
      setPartnerCost(sourceData[0].partnerCost);
      setTimescales(sourceData[0].timescales);
      setCompellingEvent(sourceData[0].compellingEvent);
      setCapabilityDeadline(sourceData[0].capabilityDeadline);
    }

  })

  const content =
    (
        <ExpandableSection headerText='Business Checklist'>
       <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size='l'>
        {formField('pricingApproach', pricingApproach, ['Yes'])}
        {formField('increaseSize', increaseSize)}
        {formField('owp', owp)}
        {formField('currentSpent', currentSpent)}
        </SpaceBetween>
        <SpaceBetween size='l'>
        {formField('buyerPersoa', buyerPersoa, ['Yes'])}
        {formField('procurementAccess', procurementAccess, ['Yes'])}
        {formField('decisionProcess', decisionProcess, ['Yes'])}
        {formField('objective', objective, ['Strategic'])}
        </SpaceBetween>
        <SpaceBetween size='l'>
        {formField('specificOutcome', specificOutcome, ['Yes'])}
        {formField('obtainAWSCapability', obtainAWSCapability, ['Yes'])}
        {formField('partnerCost', partnerCost, ['Yes'])}
        {formField('timescales', timescales, ['Strategic'])}
        
        </SpaceBetween>
        <SpaceBetween size='l'>
        {formField('compellingEvent', compellingEvent, ['Yes'])}
        {formField('capabilityDeadline', capabilityDeadline, ['Yes'])}
        </SpaceBetween>
       </ColumnLayout>
       </ExpandableSection>
    )
  return content
}
