import { Icon, ButtonDropdown, Popover, Box, SpaceBetween, ColumnLayout, StatusIndicator, ExpandableSection, Slider, FormField, Textarea } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';

export default function EditItemDetailWinLoss(props: any) {

    const data = props.data;
    const sourceData = props.sourceData;
    const updateParent = props.sendDataToParent;

    //WinLoss Items

    const [tenderOutcome, setTenderOutcome] = useState('loss');
    const [specialistEffortDays, setSpecialistEffortDays] = useState(0);
    const [specialistSAEffortDays, setSpecialistSAEffortDays] = useState(0);
    const [partner, setPartner] = useState('');
    const [solutionChosen, setSolutionChosen] = useState('');

    //Commercial
    const [commercialModel, setCommercialModel] = useState(0);
    const [commercialModelNotes, setCommercialModelNotes] = useState('');
    const [deliveryCost, setDeliveryCost] = useState(0);
    const [deliveryCostNotes, setDeliveryCostNotes] = useState('');
    const [budget, setBudget] = useState(0);
    const [budgetNotes, setBudgetNotes] = useState('');

    //Technical
    const [channels, setChannels] = useState(0);
    const [channelsNotes, setChannelsNotes] = useState('');
    const [telco, setTelco] = useState(0);
    const [telcoNotes, setTelcoNotes] = useState('');
    const [desktop, setDesktop] = useState(0);
    const [desktopNotes, setDesktopNotes] = useState('');
    const [complimentaryServices, setComplimentaryServices] = useState(0);
    const [complimentaryServicesNotes, setComplimentaryServicesNotes] = useState('');
    const [regionSupport, setRegionSupport] = useState(0);
    const [regionSupportNotes, setRegionSupportNotes] = useState('');
    const [languageSupport, setLanguageSupport] = useState(0);
    const [languageSupportNotes, setLanguageSupportNotes] = useState('');
    const [technicalDemo, setTechnicalDemo] = useState(0);
    const [technicalDemoNotes, setTechnicalDemoNotes] = useState('');
    const [technicalResponse, setTechnicalReponse] = useState(0);
    const [technicalResponseNotes, setTechnicalResponseNotes] = useState('');
    const [cloudAdoption, setCloudAdoption] = useState(0);
    const [cloudAdoptionNotes, setCloudAdoptionNotes] = useState('');

    //Relational
    const [boughtInAWS, setBoughtInAWS] = useState(0);
    const [boughtInAWSNotes, setBoughtInAWSNotes] = useState('');
    const [tenderInfluence, setTenderInfluence] = useState(0);
    const [tenderInfluenceNotes, setTenderInfluenceNotes] = useState('');
    const [existingAWSCustomer, setExistingAWSCustomer] = useState(0);
    const [existingAWSCustomerNotes, setExistingAWSCustomerNotes] = useState('');



    useEffect(() => {
        if (sourceData[0]) {
            setTenderOutcome((sourceData[0].tenderOutcome === undefined) ? 'loss' : sourceData[0].tenderOutcome);
            setPartner((sourceData[0].partner) ? sourceData[0].partner : '');
            setSolutionChosen((sourceData[0].solutionChosen) ? sourceData[0].solutionChosen : '');
            setSpecialistEffortDays((sourceData[0].specialistEffortDays) ? sourceData[0].specialistEffortDays : 0);
            setSpecialistSAEffortDays((sourceData[0].specialistSAEffortDays) ? sourceData[0].specialistSAEffortDays : 0);
            setCommercialModel((sourceData[0].commercialModel) ? sourceData[0].commercialModel : 0);
            setCommercialModelNotes((sourceData[0].commercialModelNotes) ? sourceData[0].commercialModelNotes : '');
            setDeliveryCost((sourceData[0].deliveryCost) ? sourceData[0].deliveryCost : 0);
            setDeliveryCostNotes((sourceData[0].deliveryCostNotes) ? sourceData[0].deliveryCostNotes : '');
            setBudget((sourceData[0].budget) ? sourceData[0].budget : 0);
            setBudgetNotes((sourceData[0].budgetNotes) ? sourceData[0].budgetNotes : '');
            setChannels((sourceData[0].channels) ? sourceData[0].channels : 0);
            setChannelsNotes((sourceData[0].channelsNotes) ? sourceData[0].channelsNotes : '');
            setTelco((sourceData[0].telco) ? sourceData[0].telco : 0);
            setTelcoNotes((sourceData[0].telcoNotes) ? sourceData[0].telcoNotes : '');
            setDesktop((sourceData[0].desktop) ? sourceData[0].desktop : 0);
            setDesktopNotes((sourceData[0].desktopNotes) ? sourceData[0].desktopNotes : '');
            setComplimentaryServices((sourceData[0].complimentaryServices) ? sourceData[0].complimentaryServices : 0);
            setComplimentaryServicesNotes((sourceData[0].complimentaryServicesNotes) ? sourceData[0].complimentaryServicesNotes : '');
            setRegionSupport((sourceData[0].regionSupport) ? sourceData[0].regionSupport : 0);
            setRegionSupportNotes((sourceData[0].regionSupportNotes) ? sourceData[0].regionSupportNotes : '');
            setLanguageSupport((sourceData[0].languageSupport) ? sourceData[0].languageSupport : 0);
            setLanguageSupportNotes((sourceData[0].languageSupportNotes) ? sourceData[0].languageSupportNotes : '');
            setTechnicalDemo((sourceData[0].technicalDemo) ? sourceData[0].technicalDemo : 0);
            setTechnicalDemoNotes((sourceData[0].technicalDemoNotes) ? sourceData[0].technicalDemoNotes : '');
            setTechnicalReponse((sourceData[0].technicalResponse) ? sourceData[0].technicalResponse : 0);
            setTechnicalResponseNotes((sourceData[0].technicalResponseNotes) ? sourceData[0].technicalResponseNotes : '');
            setCloudAdoption((sourceData[0].cloudAdoption) ? sourceData[0].cloudAdoption : 0);
            setCloudAdoptionNotes((sourceData[0].cloudAdoptionNotes) ? sourceData[0].cloudAdoptionNotes : '');
            setBoughtInAWS((sourceData[0].boughtInAWS) ? sourceData[0].boughtInAWS : 0);
            setBoughtInAWSNotes((sourceData[0].boughtInAWSNotes) ? sourceData[0].boughtInAWSNotes : '');
            setTenderInfluence((sourceData[0].tenderInfluence) ? sourceData[0].tenderInfluence : 0);
            setTenderInfluenceNotes((sourceData[0].tenderInfluenceNotes) ? sourceData[0].tenderInfluenceNotes : '');
            setExistingAWSCustomer((sourceData[0].existingAWSCustomer) ? sourceData[0].existingAWSCustomer : 0);
            setExistingAWSCustomerNotes((sourceData[0].existingAWSCustomerNotes) ? sourceData[0].existingAWSCustomerNotes : '');
        }

    }, [data, sourceData])

    useEffect(() => {
        updateParent({
            tenderOutcome: tenderOutcome,
            partner: partner,
            solutionChosen: solutionChosen,
            specialistEffortDays: specialistEffortDays,
            specialistSAEffortDays: specialistSAEffortDays,
            commercialModel: commercialModel,
            commercialModelNotes: commercialModelNotes,
            deliveryCost: deliveryCost,
            deliveryCostNotes: deliveryCostNotes,
            budget: budget,
            budgetNotes: budgetNotes,
            channels: channels,
            channelsNotes: channelsNotes,
            telco: telco,
            telcoNotes: telcoNotes,
            desktop: desktop,
            desktopNotes: desktopNotes,
            complimentaryServices: complimentaryServices,
            complimentaryServicesNotes: complimentaryServicesNotes,
            regionSupport: regionSupport,
            regionSupportNotes: regionSupportNotes,
            languageSupport: languageSupport,
            languageSupportNotes: languageSupportNotes,
            technicalDemo: technicalDemo,
            technicalDemoNotes: technicalDemoNotes,
            technicalResponse: technicalResponse,
            technicalResponseNotes: technicalResponseNotes,
            cloudAdoption: cloudAdoption,
            cloudAdoptionNotes: cloudAdoptionNotes,
            boughtInAWS: boughtInAWS,
            boughtInAWSNotes: boughtInAWSNotes,
            tenderInfluence: tenderInfluence,
            tenderInfluenceNotes: tenderInfluenceNotes,
            existingAWSCustomer: existingAWSCustomer,
            existingAWSCustomerNotes: existingAWSCustomerNotes
        });
    }, [tenderOutcome, specialistEffortDays, specialistSAEffortDays, commercialModel, commercialModelNotes, deliveryCost, deliveryCostNotes, budget, budgetNotes, channels, channelsNotes, telco, telcoNotes, desktop, desktopNotes, complimentaryServices, complimentaryServicesNotes, regionSupport, regionSupportNotes, languageSupport, languageSupportNotes, technicalDemo, technicalDemoNotes, technicalResponse, technicalResponseNotes, cloudAdoption, cloudAdoptionNotes, boughtInAWS, boughtInAWSNotes, tenderInfluence, tenderInfluenceNotes, existingAWSCustomer, existingAWSCustomerNotes, partner, solutionChosen])

    const content =
        (
            <ExpandableSection headerText='Win / Loss' >
                <ColumnLayout columns={4} variant="text-grid">
                    <SpaceBetween size='l'>
                        <div>
                            <Box variant='awsui-key-label'>Tender Outcome</Box>
                            <ButtonDropdown
                                items={[
                                    { text: "Win", id: "Win", disabled: false },
                                    { text: "Loss", id: "Loss", disabled: false },
                                    { text: "Pendig", id: "Pending", disabled: false }
                                ]}
                                onItemClick={(event) => {
                                    setTenderOutcome(event.detail.id);
                                }}
                            >{tenderOutcome} </ButtonDropdown>
                        </div>
                        <div>
                            <Box variant='awsui-key-label'>Commercial Specialist Effort (Days)</Box>
                            <Textarea
                                onChange={({ detail }) => setSpecialistEffortDays(Number(detail.value))}
                                value={String(specialistEffortDays)}
                                placeholder="How many Days have the Connect Commercial Specialist spent?"
                            />
                        </div>
                        <div>
                            <Box variant='awsui-key-label'>Connect SA Effort (Days)</Box>
                            <Textarea
                                onChange={({ detail }) => setSpecialistSAEffortDays(Number(detail.value))}
                                value={String(specialistSAEffortDays)}
                                placeholder="How many Days have the Connect SA spent?"
                            />
                        </div>
                        <div>
                        <Box variant='awsui-key-label'>Solution Chosen</Box>
                            <Textarea
                                onChange={({ detail }) => setSolutionChosen(String(detail.value))}
                                value={String(solutionChosen)}
                                placeholder='Solution selected'
                                />
                        </div>
                        <div>
                        <Box variant='awsui-key-label'>Partner</Box>
                            <Textarea
                                onChange={({ detail }) => setPartner(String(detail.value))}
                                value={String(partner)}
                                placeholder='Partner selected'
                                />
                        </div>
                        <div>
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size='l'>
                        <div>
                            <Box variant='awsui-key-label'>Commercial Model
                                <Popover
                                    dismissButton={true}
                                    position='top'
                                    size='small'
                                    content={<>
                                        <Box variant='awsui-key-label'>Notes</Box>
                                        <Textarea
                                            onChange={({ detail }) => setCommercialModelNotes(String(detail.value))}
                                            value={String(commercialModelNotes)}
                                            placeholder=""
                                        /></>
                                    }>
                                    <Icon name="contact" alt="Edit Notes" size='inherit' variant={(commercialModelNotes && commercialModelNotes != '' ? 'link' : 'normal')}></Icon>
                                </Popover>
                            </Box>

                            <ButtonDropdown
                                items={[
                                    { text: "1", id: "1", disabled: false },
                                    { text: "2", id: "2", disabled: false },
                                    { text: "3", id: "3", disabled: false },
                                    { text: "4", id: "4", disabled: false },
                                    { text: "5", id: "5", disabled: false },
                                    { text: "6", id: "6", disabled: false },
                                    { text: "7", id: "7", disabled: false },
                                    { text: "8", id: "8", disabled: false },
                                    { text: "9", id: "9", disabled: false },
                                    { text: "10", id: "10", disabled: false }
                                ]}
                                onItemClick={(event) => {
                                    setCommercialModel(Number(event.detail.id));
                                }}
                            >{String(commercialModel)} </ButtonDropdown>
                        </div>
                        <div>
                            <Box variant='awsui-key-label'>Delivery Cost <Popover
                                dismissButton={true}
                                position='top'
                                size='small'
                                content={<>
                                    <Box variant='awsui-key-label'>Notes</Box>
                                    <Textarea
                                        onChange={({ detail }) => setDeliveryCostNotes(String(detail.value))}
                                        value={String(deliveryCostNotes)}
                                        placeholder=""
                                    /></>
                                }>
                                <Icon name="contact" alt="Edit Notes" size='inherit'variant={(deliveryCostNotes && deliveryCostNotes != '' ? 'link' : 'normal')}></Icon>
                            </Popover>
                            </Box>
                            <ButtonDropdown
                                items={[
                                    { text: "1", id: "1", disabled: false },
                                    { text: "2", id: "2", disabled: false },
                                    { text: "3", id: "3", disabled: false },
                                    { text: "4", id: "4", disabled: false },
                                    { text: "5", id: "5", disabled: false },
                                    { text: "6", id: "6", disabled: false },
                                    { text: "7", id: "7", disabled: false },
                                    { text: "8", id: "8", disabled: false },
                                    { text: "9", id: "9", disabled: false },
                                    { text: "10", id: "10", disabled: false }
                                ]}
                                onItemClick={(event) => {
                                    setDeliveryCost(Number(event.detail.id));
                                }}
                            >{String(deliveryCost)} </ButtonDropdown>
                        </div>
                        <div>
                            <Box variant='awsui-key-label'>Budget(1-10) <Popover
                                dismissButton={true}
                                position='top'
                                size='small'
                                content={<>
                                    <Box variant='awsui-key-label'>Notes</Box>
                                    <Textarea
                                        onChange={({ detail }) => setBudgetNotes(String(detail.value))}
                                        value={String(budgetNotes)}
                                        placeholder=""
                                    /></>
                                }>
                                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(budgetNotes && budgetNotes != '' ? 'link' : 'normal')}></Icon>
                            </Popover></Box>
                            <ButtonDropdown
                                items={[
                                    { text: "1", id: "1", disabled: false },
                                    { text: "2", id: "2", disabled: false },
                                    { text: "3", id: "3", disabled: false },
                                    { text: "4", id: "4", disabled: false },
                                    { text: "5", id: "5", disabled: false },
                                    { text: "6", id: "6", disabled: false },
                                    { text: "7", id: "7", disabled: false },
                                    { text: "8", id: "8", disabled: false },
                                    { text: "9", id: "9", disabled: false },
                                    { text: "10", id: "10", disabled: false }
                                ]}
                                onItemClick={(event) => {
                                    setBudget(Number(event.detail.id));
                                }}
                            >{String(budget)} </ButtonDropdown>
                        </div>
                        <div>
                            <Box variant='awsui-key-label'>Channels(1-10) <Popover
                                dismissButton={true}
                                position='top'
                                size='small'
                                content={<>
                                    <Box variant='awsui-key-label'>Notes</Box>
                                    <Textarea
                                        onChange={({ detail }) => setChannelsNotes(String(detail.value))}
                                        value={String(channelsNotes)}
                                        placeholder=""
                                    /></>
                                }>
                                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(channelsNotes && channelsNotes != '' ? 'link' : 'normal')}></Icon>
                            </Popover></Box>
                            <ButtonDropdown
                                items={[
                                    { text: "1", id: "1", disabled: false },
                                    { text: "2", id: "2", disabled: false },
                                    { text: "3", id: "3", disabled: false },
                                    { text: "4", id: "4", disabled: false },
                                    { text: "5", id: "5", disabled: false },
                                    { text: "6", id: "6", disabled: false },
                                    { text: "7", id: "7", disabled: false },
                                    { text: "8", id: "8", disabled: false },
                                    { text: "9", id: "9", disabled: false },
                                    { text: "10", id: "10", disabled: false }
                                ]}
                                onItemClick={(event) => {
                                    setChannels(Number(event.detail.id));
                                }}
                            >{String(channels)} </ButtonDropdown>
                        </div>
                        <div>
                            <Box variant='awsui-key-label'>Telco(1-10) <Popover
                                dismissButton={true}
                                position='top'
                                size='small'
                                content={<>
                                    <Box variant='awsui-key-label'>Notes</Box>
                                    <Textarea
                                        onChange={({ detail }) => setTelcoNotes(String(detail.value))}
                                        value={String(telcoNotes)}
                                        placeholder=""
                                    /></>
                                }>
                                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(telcoNotes && telcoNotes != '' ? 'link' : 'normal')}></Icon>
                            </Popover></Box>
                            <ButtonDropdown
                                items={[
                                    { text: "1", id: "1", disabled: false },
                                    { text: "2", id: "2", disabled: false },
                                    { text: "3", id: "3", disabled: false },
                                    { text: "4", id: "4", disabled: false },
                                    { text: "5", id: "5", disabled: false },
                                    { text: "6", id: "6", disabled: false },
                                    { text: "7", id: "7", disabled: false },
                                    { text: "8", id: "8", disabled: false },
                                    { text: "9", id: "9", disabled: false },
                                    { text: "10", id: "10", disabled: false }
                                ]}
                                onItemClick={(event) => {
                                    setTelco(Number(event.detail.id));
                                }}
                            >{String(telco)} </ButtonDropdown>
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size='l'>
                        <div>
                        <Box variant='awsui-key-label'>Desktop(1-10) <Popover 
            dismissButton={true}
            position='top'
            size='small'
            content={<>
                <Box variant='awsui-key-label'>Notes</Box>
            <Textarea
                                            onChange={({ detail }) => setDesktopNotes(String(detail.value))}
                                            value={String(desktopNotes)}
                                            placeholder=""
                                        /></>
            }>
                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(desktopNotes && desktopNotes != '' ? 'link' : 'normal')}></Icon>
            </Popover></Box>
            <ButtonDropdown
                                            items={[
                                                { text: "1", id: "1", disabled: false },
                                                { text: "2", id: "2", disabled: false },
                                                { text: "3", id: "3", disabled: false },
                                                { text: "4", id: "4", disabled: false },
                                                { text: "5", id: "5", disabled: false },
                                                { text: "6", id: "6", disabled: false },
                                                { text: "7", id: "7", disabled: false },
                                                { text: "8", id: "8", disabled: false },
                                                { text: "9", id: "9", disabled: false },
                                                { text: "10", id: "10", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setDesktop(Number(event.detail.id));
                                            }}
                                        >{String(desktop)} </ButtonDropdown>

                        </div>

                        <div>
                        <Box variant='awsui-key-label'>Complimentary Services(1-10) <Popover 
            dismissButton={true}
            position='top'
            size='small'
            content={<>
                <Box variant='awsui-key-label'>Notes</Box>
            <Textarea
                                            onChange={({ detail }) => setComplimentaryServicesNotes(String(detail.value))}
                                            value={String(complimentaryServicesNotes)}
                                            placeholder=""
                                        /></>
            }>
                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(complimentaryServicesNotes && complimentaryServicesNotes != '' ? 'link' : 'normal')}></Icon>
            </Popover></Box>
            <ButtonDropdown
                                            items={[
                                                { text: "1", id: "1", disabled: false },
                                                { text: "2", id: "2", disabled: false },
                                                { text: "3", id: "3", disabled: false },
                                                { text: "4", id: "4", disabled: false },
                                                { text: "5", id: "5", disabled: false },
                                                { text: "6", id: "6", disabled: false },
                                                { text: "7", id: "7", disabled: false },
                                                { text: "8", id: "8", disabled: false },
                                                { text: "9", id: "9", disabled: false },
                                                { text: "10", id: "10", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setComplimentaryServices(Number(event.detail.id));
                                            }}
                                        >{String(complimentaryServices)} </ButtonDropdown>
                        </div>

                        <div>
                        <Box variant='awsui-key-label'>Region Support(1-10) <Popover 
            dismissButton={true}
            position='top'
            size='small'
            content={<>
                <Box variant='awsui-key-label'>Notes</Box>
            <Textarea
                                            onChange={({ detail }) => setRegionSupportNotes(String(detail.value))}
                                            value={String(regionSupportNotes)}
                                            placeholder=""
                                        /></>
            }>
                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(regionSupportNotes && regionSupportNotes != '' ? 'link' : 'normal')}></Icon>
            </Popover></Box>
            <ButtonDropdown
                                            items={[
                                                { text: "1", id: "1", disabled: false },
                                                { text: "2", id: "2", disabled: false },
                                                { text: "3", id: "3", disabled: false },
                                                { text: "4", id: "4", disabled: false },
                                                { text: "5", id: "5", disabled: false },
                                                { text: "6", id: "6", disabled: false },
                                                { text: "7", id: "7", disabled: false },
                                                { text: "8", id: "8", disabled: false },
                                                { text: "9", id: "9", disabled: false },
                                                { text: "10", id: "10", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setRegionSupport(Number(event.detail.id));
                                            }}
                                        >{String(regionSupport)} </ButtonDropdown>
                        </div>
                        <div>
                        <Box variant='awsui-key-label'>Language Support(1-10) <Popover 
            dismissButton={true}
            position='top'
            size='small'
            content={<>
                <Box variant='awsui-key-label'>Notes</Box>
            <Textarea
                                            onChange={({ detail }) => setLanguageSupportNotes(String(detail.value))}
                                            value={String(languageSupportNotes)}
                                            placeholder=""
                                        /></>
            }>
                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(languageSupportNotes && languageSupportNotes != '' ? 'link' : 'normal')}></Icon>
            </Popover></Box>
            <ButtonDropdown
                                            items={[
                                                { text: "1", id: "1", disabled: false },
                                                { text: "2", id: "2", disabled: false },
                                                { text: "3", id: "3", disabled: false },
                                                { text: "4", id: "4", disabled: false },
                                                { text: "5", id: "5", disabled: false },
                                                { text: "6", id: "6", disabled: false },
                                                { text: "7", id: "7", disabled: false },
                                                { text: "8", id: "8", disabled: false },
                                                { text: "9", id: "9", disabled: false },
                                                { text: "10", id: "10", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setLanguageSupport(Number(event.detail.id));
                                            }}
                                        >{String(languageSupport)} </ButtonDropdown>
                        </div>
                        <div>
                        <Box variant='awsui-key-label'>Technical Demo Quality(1-10) <Popover 
            dismissButton={true}
            position='top'
            size='small'
            content={<>
                <Box variant='awsui-key-label'>Notes</Box>
            <Textarea
                                            onChange={({ detail }) => setTechnicalDemoNotes(String(detail.value))}
                                            value={String(technicalDemoNotes)}
                                            placeholder=""
                                        /></>
            }>
                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(technicalDemoNotes && technicalDemoNotes != '' ? 'link' : 'normal')}></Icon>
            </Popover></Box>
            <ButtonDropdown
                                            items={[
                                                { text: "1", id: "1", disabled: false },
                                                { text: "2", id: "2", disabled: false },
                                                { text: "3", id: "3", disabled: false },
                                                { text: "4", id: "4", disabled: false },
                                                { text: "5", id: "5", disabled: false },
                                                { text: "6", id: "6", disabled: false },
                                                { text: "7", id: "7", disabled: false },
                                                { text: "8", id: "8", disabled: false },
                                                { text: "9", id: "9", disabled: false },
                                                { text: "10", id: "10", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setTechnicalDemo(Number(event.detail.id));
                                            }}
                                        >{String(technicalDemo)} </ButtonDropdown>
                        </div>
                    </SpaceBetween>
                    <SpaceBetween size='l'>
                    <div>
                    <Box variant='awsui-key-label'>Technical Response Quality <Popover 
            dismissButton={true}
            position='top'
            size='small'
            content={<>
                <Box variant='awsui-key-label'>Notes</Box>
            <Textarea
                                            onChange={({ detail }) => setTechnicalResponseNotes(String(detail.value))}
                                            value={String(technicalResponseNotes)}
                                            placeholder=""
                                        /></>
            }>
                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(technicalResponseNotes && technicalResponseNotes != '' ? 'link' : 'normal')}></Icon>
            </Popover></Box>
            <ButtonDropdown
                                            items={[
                                                { text: "1", id: "1", disabled: false },
                                                { text: "2", id: "2", disabled: false },
                                                { text: "3", id: "3", disabled: false },
                                                { text: "4", id: "4", disabled: false },
                                                { text: "5", id: "5", disabled: false },
                                                { text: "6", id: "6", disabled: false },
                                                { text: "7", id: "7", disabled: false },
                                                { text: "8", id: "8", disabled: false },
                                                { text: "9", id: "9", disabled: false },
                                                { text: "10", id: "10", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setTechnicalReponse(Number(event.detail.id));
                                            }}
                                        >{String(technicalResponse)} </ButtonDropdown>

</div>
                        <div>
                        <Box variant='awsui-key-label'>Cloud Adoption(1-10) <Popover 
            dismissButton={true}
            position='top'
            size='small'
            content={<>
                <Box variant='awsui-key-label'>Notes</Box>
            <Textarea
                                            onChange={({ detail }) => setCloudAdoptionNotes(String(detail.value))}
                                            value={String(cloudAdoptionNotes)}
                                            placeholder=""
                                        /></>
            }>
                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(cloudAdoptionNotes && cloudAdoptionNotes != '' ? 'link' : 'normal')}></Icon>
            </Popover></Box>
            <ButtonDropdown
                                            items={[
                                                { text: "1", id: "1", disabled: false },
                                                { text: "2", id: "2", disabled: false },
                                                { text: "3", id: "3", disabled: false },
                                                { text: "4", id: "4", disabled: false },
                                                { text: "5", id: "5", disabled: false },
                                                { text: "6", id: "6", disabled: false },
                                                { text: "7", id: "7", disabled: false },
                                                { text: "8", id: "8", disabled: false },
                                                { text: "9", id: "9", disabled: false },
                                                { text: "10", id: "10", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setCloudAdoption(Number(event.detail.id));
                                            }}
                                        >{String(cloudAdoption)} </ButtonDropdown>
                        </div>
                        <div>
                        <Box variant='awsui-key-label'>Bought into AWS(1-10) <Popover 
            dismissButton={true}
            position='top'
            size='small'
            content={<>
                <Box variant='awsui-key-label'>Notes</Box>
            <Textarea
                                            onChange={({ detail }) => setBoughtInAWSNotes(String(detail.value))}
                                            value={String(boughtInAWSNotes)}
                                            placeholder=""
                                        /></>
            }>
                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(boughtInAWSNotes && boughtInAWSNotes != '' ? 'link' : 'normal')}></Icon>
            </Popover></Box>
            <ButtonDropdown
                                            items={[
                                                { text: "1", id: "1", disabled: false },
                                                { text: "2", id: "2", disabled: false },
                                                { text: "3", id: "3", disabled: false },
                                                { text: "4", id: "4", disabled: false },
                                                { text: "5", id: "5", disabled: false },
                                                { text: "6", id: "6", disabled: false },
                                                { text: "7", id: "7", disabled: false },
                                                { text: "8", id: "8", disabled: false },
                                                { text: "9", id: "9", disabled: false },
                                                { text: "10", id: "10", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setBoughtInAWS(Number(event.detail.id));
                                            }}
                                        >{String(boughtInAWS)} </ButtonDropdown>
                        </div>
                        <div>
                        <Box variant='awsui-key-label'>Tender Influence(1-10) <Popover 
            dismissButton={true}
            position='top'
            size='small'
            content={<>
                <Box variant='awsui-key-label'>Notes</Box>
            <Textarea
                                            onChange={({ detail }) => setTenderInfluenceNotes(String(detail.value))}
                                            value={String(tenderInfluenceNotes)}
                                            placeholder=""
                                        /></>
            }>
                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(tenderInfluenceNotes && tenderInfluenceNotes != '' ? 'link' : 'normal')}></Icon>
            </Popover></Box>
            <ButtonDropdown
                                            items={[
                                                { text: "1", id: "1", disabled: false },
                                                { text: "2", id: "2", disabled: false },
                                                { text: "3", id: "3", disabled: false },
                                                { text: "4", id: "4", disabled: false },
                                                { text: "5", id: "5", disabled: false },
                                                { text: "6", id: "6", disabled: false },
                                                { text: "7", id: "7", disabled: false },
                                                { text: "8", id: "8", disabled: false },
                                                { text: "9", id: "9", disabled: false },
                                                { text: "10", id: "10", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setTenderInfluence(Number(event.detail.id));
                                            }}
                                        >{String(tenderInfluence)} </ButtonDropdown>
                        </div>
                        <div>
                        <Box variant='awsui-key-label'>Existing AWS Customer(1-10) <Popover 
            dismissButton={true}
            position='top'
            size='small'
            content={<>
                <Box variant='awsui-key-label'>Notes</Box>
            <Textarea
                                            onChange={({ detail }) => setExistingAWSCustomerNotes(String(detail.value))}
                                            value={String(existingAWSCustomerNotes)}
                                            placeholder=""
                                        /></>
            }>
                <Icon name="contact" alt="Edit Notes" size='inherit' variant={(existingAWSCustomerNotes && existingAWSCustomerNotes != '' ? 'link' : 'normal')}></Icon>
            </Popover></Box>
            <ButtonDropdown
                                            items={[
                                                { text: "1", id: "1", disabled: false },
                                                { text: "2", id: "2", disabled: false },
                                                { text: "3", id: "3", disabled: false },
                                                { text: "4", id: "4", disabled: false },
                                                { text: "5", id: "5", disabled: false },
                                                { text: "6", id: "6", disabled: false },
                                                { text: "7", id: "7", disabled: false },
                                                { text: "8", id: "8", disabled: false },
                                                { text: "9", id: "9", disabled: false },
                                                { text: "10", id: "10", disabled: false }
                                            ]}
                                            onItemClick={(event) => {
                                                setExistingAWSCustomer(Number(event.detail.id));
                                            }}
                                        >{String(existingAWSCustomer)} </ButtonDropdown>
                        </div>
                        <div>

                        </div>
                        <div>

                        </div>
                        
                    </SpaceBetween>



                </ColumnLayout>
            </ExpandableSection>
        )
    return content
}
