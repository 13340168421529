import { Box, StatusIndicator, Popover, TextContent, Textarea } from '@cloudscape-design/components';
import { fieldDefinition } from './fieldDefinitionx';

export const formField = (fieldname:string, value:string | number, successValue?: string | number | string[] , suffix?: string)  => {
    if (typeof value === 'number') {
      console.log(`${fieldname} is a number with`)
      if (successValue) {
        return (<>
          <div>
            <Box variant="awsui-key-label">{fieldDefinition.filter((field) => field.valueName === fieldname).map((field) => {
              return (<>{field.label} <Popover dismissButton={false} position='top' size='small' content={field.description}><small>Info</small></Popover></>)
            })}</Box>
            <StatusIndicator type={value >= Number(successValue) ? 'success' : 'warning'}>{value}{suffix}</StatusIndicator>
          </div>
        </>)
      }
    }

    if(successValue && typeof successValue === 'object') {
      console.log(`Object as successValue`);
        return (<>
          <div>
            <Box variant="awsui-key-label">{fieldDefinition.filter((field) => field.valueName === fieldname).map((field) => {
              return (<>
              {field.label} <Popover dismissButton={false} position='top' size='small' content={field.description}><small>Info</small></Popover></>)
            })}</Box>
            <StatusIndicator type={successValue.includes(String(value)) ? 'success' : 'warning'}>{value}{suffix}</StatusIndicator>
          </div>
        </>)
    }


    const valueReturn = fieldDefinition.filter((field) => field.valueName === fieldname).map((field) => {
      return (<>
      <div>
        <Box variant="awsui-key-label"> {field.label} <Popover dismissButton={false} position='top' size='small' content={field.description}><small>Info</small></Popover></Box>
        <StatusIndicator type={value == 'Yes' ? 'success' : 'warning'}>{value}</StatusIndicator>
        </div>
      </>)
    })
    return valueReturn
  } 

export const formTextBoxField = (fieldname:string, value:string, readonly: boolean) => {
    if(readonly && readonly === true)  {

        return (<>
            <div>
            <Box variant="awsui-key-label">{fieldDefinition.filter((field) => field.valueName === fieldname).map((field) => {
              return (<>
              {field.label} <Popover dismissButton={false} position='top' size='small' content={field.description}><small>Info</small></Popover></>)
            })}</Box>
             <Textarea
            value={value}
            disabled
            rows={2} />
              </div>
            </>)
    }
    const valueReturn = fieldDefinition.filter((field) => field.valueName === fieldname).map((field) => {
        return (<>
        <div>
          <Box variant="awsui-key-label"> {field.label} <Popover dismissButton={false} position='top' size='small' content={field.description}><small>Info</small></Popover></Box>
          <Textarea
            value={value}
            rows={2} />
          </div>
        </>)
      })
      return valueReturn

}