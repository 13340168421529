export type FieldDefinition = {
    valueName: string;
    label: string;
    description: string;
} 

export const fieldDefinition: FieldDefinition[] = [
    { valueName: "pricingApproach", label: "Pricing Approach", description: "Is the customer budgeted for and bought into our consumption pricing approach?" },
    { valueName: "increaseSize", label: "Increase Size", description: "Is there an initiative to increase the size of the operation?" },
    { valueName: "owp", label: "OWP", description: "Do we have an opportunity win plan (OWP)?" },
    { valueName: "currentSpent", label: "Current Spent", description: "Have they told us that they have defined budget/do we know their current spend ?" },
    { valueName: "buyerPersoa", label: "Buyer Persona", description: "Are we talking to a buying or approving persona?" },
    { valueName: "procurementAccess", label: "Procurement Access", description: "Do we have a direct relationship with the economic buyer (i.e. procurement)?" },
    { valueName: "decisionProcess", label: "Decision Process", description: "Do we know what the buying/decision making process is (scoring, price, weighting etc)?" },
    { valueName: "objective", label: "Objective", description: "What is the objective? Tactical optimisation vs strategic transformation" },
    { valueName: "specificOutcome", label: "Specific Outcome", description: "Has the customer expressed specific outcomes for the delivery of new cababilities?" },
    { valueName: "obtainAWSCapability", label: "Obtain AWS Capability", description: "Has the customer expressed a desire to obtain a capability they don't have, which AWS could provide?" },
    { valueName: "partnerCost", label: "Partner Cost", description: "Has the customer expressed a need to receive implementation/consulting partner costs ?" },
    { valueName: "timescales", label: "Timescales", description: "Do we know the expected implementation timescales?" },
    { valueName: "compellingEvent", label: "Compelling Event", description: "Has their current solution met its vendor's end of life or is there a major compelling event?" },
    { valueName: "capabilityDeadline", label: "Capability Deadline", description: "Has an executive imposed/declared a deadline for having new capabilities? E.g., New regulation, product launch" },
    { valueName: 'frameworkTender', label:"Framework Tender", description:"Does this tender fall within a tender framework that we have access to?"},
    { valueName: 'awsCustomer', label:"AWS Customer", description:"Is this customer an existing AWS customer ?"},
    { valueName: 'influence', label:"Influence", description:"Have we been involved in the process to influence the RFP"},
    { valueName: 'competition', label:"Competition", description:"Do we know who the competition are ?"},
    { valueName: 'accountPlan', label:"Account Plan", description:"Do we have an account plan?"},
    { valueName: 'opportunitySeatSize', label:"Opportunity Seat Size", description:"How many contact centre seats does this customer have?"},
    { valueName: 'opportunityType', label:"Opportunity Type", description:"What is the mix of digital and voice channels within this opportunity "},
    { valueName: 'expectedARR', label:"Expected ARR", description:"What is the expected ARR for this opportunity in Millions (Examples, $1.1 or $0.7)"},
    { valueName: 'awsSentiment', label:"AWS Sentiment", description:"Is the decision maker bought into AWS (i.e. champion, all-in) ?"},
    { valueName: 'buildVsBuy', label:"Builder or Buyer", description:"Does the business have a build or buy mentality?"},
    { valueName: 'effort', label:"Estimated Effort", description:"What is the estimated effort to respond (Please consider end to end efforts until final outcome i.e. demo etc)"},
    { valueName: 'partnerLed', label:"Partner led", description:"Is this partner led and who is the partner ?"},
    { valueName: 'consultingPartner', label:"Consulting Partner", description:"If this is consulting led, are they promoters of AWS ?"},
    { valueName: 'tap', label: "TAP", description: "Do we have a Technical Account Plan (TAP)?" },
    { valueName: 'operationalAreas', label: "Operational Areas", description: "Does the customer have other operational areas, such as branches or homeworkers?" },
    { valueName: 'multipleCountries', label: "Multiple Countries", description: "Does the customer operate in multiple regions/countries?" },
    { valueName: 'redFlagCountries', label: "Red-Flag Countries", description: "Are there any red flag countries (China, Russia, India etc) ?" },
    { valueName: 'matchingLanguageSet', label: "Language Set matching", description: "Do we have adequate language support for the customers regions/countries ?" },
    { valueName: 'nativeChannelsSupport', label: "Channels natively supported", description: "Do we support all channels (being requested) natively?" },
    { valueName: 'drOnly', label: "DR-only solution", description: "Is this a Disaster-Recovery(DR) solution only?" },
    { valueName: 'percentageOutboundDialer', label: "Outbound Call percentage", description: "What percentage of calls are outbound (dialler heavy)?" },
    { valueName: 'e2eSolutionAware', label: "Solution Components", description: "Do we understand the end to end solution components (architectures) i.e. WFM, Reporting, CRM etc" },
    { valueName: 'deliveryModel', label: "Delivery Model", description: "Do we understand the delivery model that serves the customer best (Partner or Proserve)" },
    { valueName: 'tenderOtherVendor', label: "Tender bias", description: "Does this tender read as though it has been written for another vendor?" },
    { valueName: 'cloudCustomer', label: "Cloud Buy-In", description: "Is the customer bought into Cloud ?" },
    { valueName: 'byoc', label: "BYOC", description: "Is there an expectation for BYOC?" },
    { valueName: 'pbxRequirements', label: "PBX Requirements", description: "Are there Unified Communications (PBX) requirements as a part of the RFP?" },
    { valueName: 'backOfficeIntegration', label: "Backoffice integrations", description: "Does the customer require back office integration (i.e. MS Team)" },
    { valueName: 'followTheSun', label: "Follow-the-Sun", description: "Is this a Global solution (do they require follow the sun requirements)?" },
    { valueName: 'requirementNature', label: "Nature of Requirements", description: "Please describe the nature of the customers requirement." },
    { valueName: 'nextSteps', label: "Next steps", description: "Wha/0  are the next steps?" },
    { valueName: 'setExpectations', label: "Customer expectations", description: "What expectations have been set with the customer ?" },
    { valueName: 'solutionConsultant', label: "Solutions Consultant", description: "Do you have a solutions consultant aligned to this opporunity already, if so whom?" },
    { valueName: 'crmSolution', label: "CRM Solution", description: "Do we know the customers CRM solution(s), if so what are they?" },
    { valueName: 'existingSolution', label: "Existing Solution", description: "What solution technologies exist today ?" },
    { valueName: 'existingCloudOrPremise', label: "Cloud or On-Premis", description: "Are the existing solutions a cloud or on-prem?" },
    { valueName: 'solutionFit', label: "Solution Fit", description: "" },
    { valueName: 'solutionFitComments', label: "Solution Fit Comments", description: "" }
]



