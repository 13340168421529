import { Box, SpaceBetween, ColumnLayout, StatusIndicator, ExpandableSection, Slider } from '@cloudscape-design/components';
import React, { useEffect, useState } from 'react';
import { fieldDefinition, FieldDefinition } from './common/fieldDefinitionx';
import { formField } from './common/createField';

export default function ItemDetailQualificationChecklist(props: any) {

  const data = props.data;
  const sourceData = props.sourceData;
  const qualiChecklistScore = data.qualiChecklistScore;
  const qualiChecklistType = data.type;

  //Basic Info Fields
  const [oppyDeadline, setOppyDeadline] = useState("");
  const [awsAccountExec, setAwsAccountExec] = useState("");
  const [salesforceOppyURL, setSalesforceOppyURL] = useState("https://aws-crm.lightning.force.com/lightning/r/Opportunity/[Opportunity ID]");
  const [oppyForecastedDate, setOppyForecastedDate] = useState('');
  const [connectSpecialist, setConnectSpecialist] = useState('');

  // Customer Info Fields 
  const [companyName, setCompanyName] = useState("");
  const [companyIndustry, setCompanyIndustry] = useState("");
  const [mainOfficeAddress, setMainOfficeAddress] = useState("");
  const [regionCountry, setRegionCountry] = useState("");
  const [primaryBusinessContactName, setPrimaryBusinessContactName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");

  // Checklist Input Fields
  const [frameworkTender, setFrameworkTender] = useState("No");
  const [awsCustomer, setAwsCustomer] = useState("No");
  const [influence, setInfluence] = useState("No");
  const [competition, setCompetition] = useState("No");
  const [accountPlan, setAccountPlan] = useState("No");
  const [opportunitySeatSize, setOpportunitySeatSize] = useState("99");
  const [opportunityType, setOpportunityType] = useState("Digital only");
  const [expectedARR, setExpectedARR] = useState(1);
  const [awsSentiment, setAwsSentiment] = useState("No");
  const [buildVsBuy, setBuildVsBuy] = useState("Build");
  const [effort, setEffort] = useState(2);
  const [partnerLed, setPartnerLed] = useState("Yes");
  const [consultingPartner, setConsultinPartner] = useState("No");

  useEffect(() => {
    if (sourceData[0]) {
      setOppyDeadline(sourceData[0].oppyDeadline);
      setAwsAccountExec(sourceData[0].awsAccountExec);
      setCompanyName(sourceData[0].companyName);
      setCompanyIndustry(sourceData[0].companyIndustry);
      setMainOfficeAddress(sourceData[0].mainOfficeAddress);
      setRegionCountry(sourceData[0].regionCountry);
      setPrimaryBusinessContactName(sourceData[0].primaryBusinessContactName);
      setPhoneNumber(sourceData[0].phoneNumber);
      setEmailAddress(sourceData[0].emailAddress);
      setSalesforceOppyURL(sourceData[0].salesforceOppyURL);
      setConnectSpecialist(sourceData[0].connectSpecialist);
      setOppyForecastedDate(sourceData[0].oppyForecastedDate);
      setFrameworkTender(sourceData[0].frameworkTender);
      setAwsCustomer(sourceData[0].awsCustomer);
      setInfluence(sourceData[0].influence);
      setCompetition(sourceData[0].competition);
      setAccountPlan(sourceData[0].accountPlan);
      setOpportunitySeatSize(sourceData[0].opportunitySeatSize);
      setOpportunityType(sourceData[0].opportunityType);
      setExpectedARR(sourceData[0].expectedARR);
      setAwsSentiment(sourceData[0].awsSentiment);
      setBuildVsBuy(sourceData[0].buildVsBuy);
      setEffort(sourceData[0].effort);
      setPartnerLed(sourceData[0].partnerLed);
      setConsultinPartner(sourceData[0].consultingPartner);


    }

  })


  const content =
    (
      <ExpandableSection headerText='Opportunity Information'>
        <ColumnLayout columns={4} variant="text-grid">
          <SpaceBetween size="l">
              {formField('frameworkTender', frameworkTender)}
              {formField('awsCustomer', awsCustomer)}
              {formField('influence', influence)}
              {formField('competition', competition)}
          </SpaceBetween>

          <SpaceBetween size="l">
          {formField('accountPlan', accountPlan)}
          {formField('opportunitySeatSize', Number(opportunitySeatSize), 100)}
          {formField('opportunityType', opportunityType, ['Voice Only', 'Voice + Digital'])}
          {formField('expectedARR', Number(expectedARR), 0.5, 'M USD')}
          </SpaceBetween>

          <SpaceBetween size="l">
          {formField('awsSentiment', awsSentiment)}
              {formField('buildVsBuy', buildVsBuy, ['Build'])}
              {formField('partnerLed', partnerLed)}
              {formField('consultingPartner', consultingPartner)}
          </SpaceBetween>

          <SpaceBetween size="l">
            <Box variant="awsui-key-label">Estimated Effort</Box>
            <Slider
              warning={(effort === 3 ? true : false)}
              value={effort}
              valueFormatter={value =>
                [
                  { value: "0", label: "None" },
                  { value: "1", label: "Low" },
                  { value: "2", label: "Medium" },
                  { value: "3", label: "High" }
                ].find(item => item.value === value.toString())
                  ?.label || ""
              }
              ariaDescription="From None to High"
              max={3}
              min={0}
              referenceValues={[1, 2]}
              step={1}
              tickMarks />
          </SpaceBetween>
        </ColumnLayout>
      </ExpandableSection>
    )
  return content
}
