import { Container, Header ,Box, SpaceBetween, ColumnLayout, StatusIndicator, ExpandableSection, Slider, FormField, Textarea } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { formField, formTextBoxField } from './common/createField';

export default function ItemDetailSolutionsChecklist(props: any) {

  const data = props.data;
  const sourceData = props.sourceData;
  //Solutions Fit Items

    //Business Landscape
    const [tap, setTAP] = useState('No');
    const [operationalAreas, setOperationalAreas] = useState('No');
    const [multipleCountries, setMultipleCountries] = useState('No');
    const [redFlagCountries, setRedFlagCountries] = useState('No');
    const [matchingLanguageSet, setMatchingLanguageSet] = useState('No');
    const [nativeChannelsSupport, setNativeChannelsSupport] = useState('No');
    const [drOnly, setDROnly] = useState('No');
    const [crmSolution, setCRMSolution] = useState('');

    //Existing Tech Landscape

    const [existingSolution, setExistingSolution] = useState('');
    const [existingCloudOrPremise, setExistingCloudOrPromise] = useState('');
    const [percentageOutboundDialer, setPercentageOutboundDialer] = useState(20);
    const [e2eSolutionAware, sete2eSolutionAware] = useState('No');
    const [deliveryModel, setDeliveryModel] = useState('No');
    const [tenderOtherVendor, setTenderOtherVendor] = useState('No');

    //Solutions Landscape
    const [cloudCustomer, setCloudCustomer] = useState('No');
    const [byoc, setBYOC] = useState('No');
    const [pbxRequirements, setPBXRequirements] = useState('No');
    const [backOfficeIntegration, setBackOfficeIntegration] = useState('No');
    const [followTheSun, setFollowTheSun] = useState('No');
    const [requirementNature, setRequirementNature] = useState('');

    //Next Steps
    const [nextSteps, setNextSteps] = useState('');
    const [setExpectations, setSetExpectations] = useState('');
    const [solutionConsultant, setSolutionConsultant] = useState('');


  useEffect(() => {
    if (sourceData[0]) {
      setOperationalAreas(sourceData[0].operationalAreas);
      setMultipleCountries(sourceData[0].multipleCountries);
      setRedFlagCountries(sourceData[0].redFlagCountries);
      setMatchingLanguageSet(sourceData[0].matchingLanguageSet);
      setNativeChannelsSupport(sourceData[0].nativeChannelsSupport);
      setDROnly(sourceData[0].drOnly);
      setCRMSolution(sourceData[0].crmSolution);
      setExistingCloudOrPromise(sourceData[0].existingCloudOrPremise);
      setExistingSolution(sourceData[0].existingSolution);
      setPercentageOutboundDialer(sourceData[0].percentageOutboundDialer);
      sete2eSolutionAware(sourceData[0].e2eSolutionAware);
      setDeliveryModel(sourceData[0].deliveryModel);
      setTenderOtherVendor(sourceData[0].tenderOtherVendor);
      setCloudCustomer(sourceData[0].cloudCustomer);
      setBYOC(sourceData[0].byoc);
      setPBXRequirements(sourceData[0].pbxRequirements);
      setBackOfficeIntegration(sourceData[0].backOfficeIntegration);
      setFollowTheSun(sourceData[0].followTheSun);
      setRequirementNature(sourceData[0].requirementNature);
      setNextSteps(sourceData[0].nextSteps);
      setSetExpectations(sourceData[0].setExpectations);
      setSolutionConsultant(sourceData[0].solutionConsultant);
    }

  })

  const content =
    (
      <ExpandableSection headerText='Solutions Fit Checklist' >
       <ColumnLayout columns={4} variant="text-grid">
        <SpaceBetween size='l'>
        {formField('tap', tap)}
        {formField('operationalAreas', operationalAreas, ['No'])}
        {formField('multipleCountries', multipleCountries, ['No'])}
        {formField('redFlagCountries', redFlagCountries, ['No'])}
        {formField('matchingLanguageSet', matchingLanguageSet)}
        {formField('cloudCustomer', cloudCustomer)}
        </SpaceBetween>
        <SpaceBetween size='l'>
        {formField('nativeChannelsSupport', nativeChannelsSupport)}
        {formField('drOnly', drOnly, ['No'])}
        {formField('percentageOutboundDialer', percentageOutboundDialer)}  //! TO FIX Negative success value
        {formField('e2eSolutionAware', e2eSolutionAware)}
        {formField('deliveryModel', deliveryModel)}
        </SpaceBetween>
        <SpaceBetween size='l'>
        {formField('tenderOtherVendor', tenderOtherVendor, ['No'])}
        {formField('cloudCustomer', cloudCustomer)}
        {formField('byoc', byoc, ['No'])}
        {formField('followTheSun', followTheSun, ['No'])}
        {formField('backOfficeIntegration', backOfficeIntegration, ['No'])}
        </SpaceBetween>
        <SpaceBetween size='l'>
          {formTextBoxField('crmSolution', crmSolution, true)}
          {formTextBoxField('existingSolution', existingSolution, true)}
          {formTextBoxField('existingCloudOrPremise', existingCloudOrPremise, true)}
          {formTextBoxField('requirementNature', requirementNature, true)}
          {formTextBoxField('nextSteps', nextSteps, true)}
          {formTextBoxField('setExpectations', setExpectations, true)}
          {formTextBoxField('solutionConsultant', solutionConsultant, true)}
        </SpaceBetween>
          
          
          
       </ColumnLayout>
      </ExpandableSection>
    )
  return content
}
